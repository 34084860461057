import React, { useEffect, useRef, useState } from "react";

import { Downgraded, useState as useHookState } from "@hookstate/core";
import { AxiosError } from "axios";
import { useForm, SubmitHandler } from "react-hook-form";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { useMutation } from "@apollo/client";

import Loader from "../Loader";

import NoImg from "../../images/no_image_icon@2x.png";
import EditIcon from "../../images/Edit_file_photo@2x.png";
import PrfileIcon from "../../images/profile_user_icon@2x.png";
import PhoneIcon from "../../images/profile_phone_icon@2x.png";
import MailIcon from "../../images/profile_mail_icon@2x.png";
import GenderIcon from "../../images/Profile_gender_icon@2x.png";
import LocationIcon from "../../images/profile_location_icon-1@2x.png";

import States from "../../Data/state.json";

import AppState from "../../States/AppState";

import { getImageUrl } from "../../utils/GetPhotoUrl";
import { popoverAlert } from "../../utils/Alert";

import * as ProfileStyle from "./Profile.module.scss";
import Server from "../../utils/Server";
import {
  CreateUserAddressQuery,
  UpdateUserAddressQuery,
  UpdateUserQuery,
} from "../../Query/User";
import { User } from "../../Interfaces/User";
import { CreateUserAddress, UpdateUserAddress } from "../../Interfaces/Address";
import { Media } from "../../Interfaces/Media";
import Logger from "../../utils/Logger";

interface Inputs {
  name: string;
  contactNumber: string;
  email: string;
  gender: "MALE" | "FEMALE";
  address: string;
}

const Index = () => {
  const [isLoading, setIsLoading] = useState(false);
  const uploadProfileRef = useRef<HTMLInputElement>(null);

  const user = useHookState(AppState).attach(Downgraded).user.get();
  const [isViewOnlyMode, setIsViewOnlyMode] = useState(true);
  const [selectedCity, setSelectedCity] = useState<string | undefined>(
    user?.address?.city.id
  );
  const [selectedState, setSelectedState] = useState<string | undefined>(
    user?.address?.state.id
  );
  const [selectedPincode, setSelectedPincode] = useState<string | undefined>(
    user?.address?.pincode.id
  );

  const [updateUser, { loading: updaingUser }] =
    useMutation<{ updateUser: { user: User } }>(UpdateUserQuery);

  const [updateUserAddress, { loading: updatingUserAddress }] =
    useMutation<UpdateUserAddress>(UpdateUserAddressQuery);
  const [createUserAddress, { loading: creatingUserAddress }] =
    useMutation<CreateUserAddress>(CreateUserAddressQuery);

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (user?.id) {
      setValue("name", user.name);
      setValue("contactNumber", user.contactNumber);
      setValue("email", user.email);
      setValue("gender", user.gender?.code);
      setValue("address", user.userAddresses?.collection[0]?.address);
    }
  }, [user?.id]);

  const defaultPincode = {
    value: user?.userAddresses?.collection[0]?.pincode.id,
    label: user?.userAddresses?.collection[0]?.pincode?.pincode,
  };

  const defaultCity = {
    value: user?.userAddresses?.collection[0]?.city.id,
    label: user?.userAddresses?.collection[0]?.city?.name,
  };

  const defaultState = {
    value: user?.userAddresses?.collection[0]?.state.id,
    label: user?.userAddresses?.collection[0]?.state?.name,
  };

  const fetchCity = (searchStr: string) => {
    return new Promise((resolve, reject) => {
      const shortStateId = selectedState?.split("/")[2];
      Server.get(`/cities?state=${shortStateId}&name=${searchStr}`)
        .then((res: any) => {
          resolve(
            res.map((c: any) => {
              return {
                value: c.id,
                label: c.name,
              };
            })
          );
        })
        .catch((err: AxiosError) => {
          reject();
          console.log(err.response?.data.detail);
        });
    });
  };

  const fetchPostalCode = (searchStr: string) => {
    return new Promise((resolve, reject) => {
      const shortStateId = selectedState?.split("/")[2];
      Server.get(`/postal_codes?state=${shortStateId}&pincode=${searchStr}`)
        .then((res: any) => {
          resolve(
            res.map((c: any) => {
              return {
                value: c.id,
                label: `${c.pincode} (${c.officeName})`,
              };
            })
          );
        })
        .catch((err: AxiosError) => {
          reject();
          console.log(err.response?.data.detail);
        });
    });
  };

  const customStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      color: "#3880ff",
      transform: "translateY(-3px)",
      zIndex: "4",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      borderBottom: "#3880ff",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      outline: "none",
      backgroundColor: "none",
      border: "none",
      color: "red",
      borderRadius: "0px",
      height: "30px",
      minHeight: "100%",
      borderBottom: isViewOnlyMode
        ? "1px solid rgb(214, 205, 205)"
        : "2px solid #3880ff",
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      display: "none",
    }),
    Input: (provided: any, state: any) => ({
      ...provided,
      margin: "0px !important",
      fontSize: "14px",
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      padding: "1px",
      fontSize: "13px",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: isViewOnlyMode ? "rgb(84, 84, 84)" : "#000",
    }),
  };

  const createUpdateUserInfo = (data: Inputs): Promise<User> => {
    return new Promise((resolve, reject) => {
      updateUser({
        variables: {
          id: user?.id,
          contactNumber: data.contactNumber,
          email: data.email,
          name: data.name,
        },
      })
        .then((userResponse) => {
          if (user?.userAddresses?.collection[0]?.id) {
            updateUserAddress({
              variables: {
                id: user?.userAddresses?.collection[0]?.id,
                address: data.address.length ? data.address : undefined,
                city: selectedCity,
                state: selectedState,
                pincode: selectedPincode,
              },
            })
              .then((addressResponse) => {
                const newAddress =
                  addressResponse.data?.updateUserAddress.userAddress;
                if (newAddress)
                  resolve({
                    ...userResponse.data?.updateUser.user,
                    userAddresses: {
                      collection: user?.userAddresses?.collection
                        ? [newAddress, ...user.userAddresses.collection]
                        : [newAddress],
                    },
                  });
                else reject(new Error("No Data"));
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            if (
              data.address.length &&
              selectedCity?.length &&
              selectedState?.length &&
              selectedPincode?.length
            )
              createUserAddress({
                variables: {
                  user: user?.id,
                  address: data.address,
                  city: selectedCity,
                  state: selectedState,
                  pincode: selectedPincode,
                },
              })
                .then((addressResponse) => {
                  const newAddress =
                    addressResponse.data?.createUserAddress.userAddress;
                  if (newAddress)
                    resolve({
                      ...userResponse.data?.updateUser.user,
                      userAddresses: {
                        collection: user?.userAddresses?.collection
                          ? [newAddress, ...user.userAddresses.collection]
                          : [newAddress],
                      },
                    });
                  else reject(new Error("No Data"));
                })
                .catch((err) => {
                  reject(err);
                });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const onHandleSubmit: SubmitHandler<Inputs> = (data) => {
    createUpdateUserInfo(data)
      .then((user) => {
        popoverAlert({
          icon: "success",
          msg: "Profile Updated",
          title: "Success",
        });
        AppState.user.set({ ...user });
      })
      .catch((err) => {
        Logger.showError(err);
      })
      .finally(() => {
        setIsLoading(false);
        setIsViewOnlyMode(true);
      });
  };

  const changeProfileImg = (img: File): Promise<Media> => {
    return new Promise((resolve, reject) => {
      if (user?.userMedias?.collection.length) {
        deleteProfileImg(user.userMedias.collection[0]?.id)
          .then(() => {
            uploadProfileImg(img)
              .then((res) => {
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        uploadProfileImg(img)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  };

  const deleteProfileImg = (imgId: string) => {
    return new Promise((resolve, reject) => {
      Server.del(`/usermedia/${imgId}`)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  };

  const uploadProfileImg = (img: File): Promise<Media> => {
    return new Promise((resolve, reject) => {
      Server.get("/s3SignedUrl?filename=user&mime=image%2Fjpeg")
        .then((response: any) => {
          const formData = new FormData();
          formData.append("file", img);
          Server.put(response?.url, formData)
            .then((res) => {
              const shortUserId = user?.id?.split("/")[2];
              Server.get(
                `/user/${shortUserId}/media?keyname=${encodeURIComponent(
                  response?.key
                )}&mediaType=GENERAL`
              )
                .then((r: any) => {
                  resolve(r);
                })
                .catch((error) => {
                  reject(error);
                });
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  if (!AppState.user.get()?.id) return <div></div>;

  return (
    <div className={ProfileStyle.container}>
      <h4 className={ProfileStyle.heading}>My Profile</h4>
      <div className={ProfileStyle.profile__card}>
        <div className={`${ProfileStyle.profile__card__header} grediant1`}>
          <button
            onClick={() => uploadProfileRef.current?.click()}
            className={ProfileStyle.profile__card__header__img}
          >
            <img
              src={user?.userMedias?.collection[0]?.mediaUrl?.url || NoImg}
              alt="profile"
            />
            <span>Change Photo</span>
          </button>
          <button
            onClick={() => setIsViewOnlyMode(!isViewOnlyMode)}
            className={ProfileStyle.profile__card__header__edit}
          >
            <span>Edit Profile</span>
            <img src={EditIcon} alt="edit" width={28} height={28} />
          </button>
        </div>
        <form
          onSubmit={handleSubmit(onHandleSubmit)}
          className={`${ProfileStyle.profile__card__content} ${
            isViewOnlyMode ? ProfileStyle.paddingBottom : ""
          }`}
        >
          <div className={ProfileStyle.profile__card__content__item}>
            <img src={PrfileIcon} alt="profile" width={42} height={42} />
            <div className={ProfileStyle.profile__card__content__item__div}>
              <h6>Name</h6>
              <input
                className={ProfileStyle.input}
                {...register("name")}
                disabled={isViewOnlyMode}
              />
            </div>
          </div>
          <div className={ProfileStyle.profile__card__content__item}>
            <img src={PhoneIcon} alt="profile" width={42} height={42} />
            <div className={ProfileStyle.profile__card__content__item__div}>
              <h6>Contact Number</h6>
              <input
                className={ProfileStyle.input}
                {...register("contactNumber")}
                disabled={isViewOnlyMode}
              />
            </div>
          </div>
          <div className={ProfileStyle.profile__card__content__item}>
            <img src={MailIcon} alt="profile" width={42} height={42} />
            <div className={ProfileStyle.profile__card__content__item__div}>
              <h6>Email Address</h6>
              <input
                className={ProfileStyle.input}
                {...register("email")}
                disabled={isViewOnlyMode}
              />
            </div>
          </div>
          <div className={ProfileStyle.profile__card__content__item}>
            <img src={GenderIcon} alt="profile" width={42} height={42} />
            <div className={ProfileStyle.profile__card__content__item__div}>
              <h6>Gender</h6>
              <select
                className={ProfileStyle.select}
                disabled={isViewOnlyMode}
                {...register("gender")}
              >
                <option value="MALE">MALE</option>
                <option value="FEMALE">FEMALE</option>
              </select>
            </div>
          </div>
          <div className={ProfileStyle.profile__card__content__item}>
            <img src={LocationIcon} alt="profile" width={42} height={42} />
            <div className={ProfileStyle.profile__card__content__item__div}>
              <h6>Address</h6>
              <input
                className={ProfileStyle.input}
                {...register("address")}
                disabled={isViewOnlyMode}
              />
            </div>
          </div>
          <div className={ProfileStyle.profile__card__content__item}>
            <img src={LocationIcon} alt="profile" width={42} height={42} />
            <div className={ProfileStyle.profile__card__content__item__div}>
              <h6>State</h6>
              <Select
                isDisabled={isViewOnlyMode}
                menuPlacement="top"
                options={States.map(({ name, id, ISO }) => {
                  return {
                    value: `/states/${id}`,
                    label: `${name} (${ISO})`,
                  };
                })}
                defaultValue={defaultState}
                placeholder="Select State"
                styles={customStyles}
                onChange={(e: any) => {
                  setSelectedState(e?.value);
                }}
              />
            </div>
          </div>
          <div className={ProfileStyle.profile__card__content__item}>
            <img src={LocationIcon} alt="profile" width={42} height={42} />
            <div className={ProfileStyle.profile__card__content__item__div}>
              <h6>Pincode</h6>
              <AsyncSelect
                isDisabled={isViewOnlyMode}
                menuPlacement="top"
                defaultValue={defaultPincode}
                loadOptions={fetchPostalCode}
                placeholder="Select Pincode"
                styles={customStyles}
                onChange={(e: any) => {
                  setSelectedPincode(`postal_codes/${e?.value}`);
                }}
              />
            </div>
          </div>
          <div className={ProfileStyle.profile__card__content__item}>
            <img src={LocationIcon} alt="profile" width={42} height={42} />
            <div className={ProfileStyle.profile__card__content__item__div}>
              <h6>City</h6>
              <AsyncSelect
                isDisabled={isViewOnlyMode}
                menuPlacement="top"
                defaultValue={defaultCity}
                loadOptions={fetchCity}
                placeholder="Select City"
                styles={customStyles}
                onChange={(e: any) => {
                  setSelectedCity(`cities/${e?.value}`);
                }}
              />
            </div>
          </div>
          <div
            className={
              isViewOnlyMode ? ProfileStyle.disable : ProfileStyle.submit
            }
          >
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
      <input
        hidden
        ref={uploadProfileRef}
        type="file"
        accept="image/png, image/gif, image/jpeg"
        onChange={(e) => {
          if (e.target.files) {
            setIsLoading(true);
            changeProfileImg(e.target.files[0])
              .then((response) => {
                AppState.user.set({
                  ...user,
                  userMedias: {
                    collection: [
                      {
                        id: `${response.id}`,
                        mediaUrl: {
                          url: response.url.url,
                          expiresIn: 9000000,
                        },
                      },
                    ],
                  },
                });
              })
              .catch((err) => {
                Logger.showError(err);
              })
              .finally(() => setIsLoading(false));
          }
        }}
      />
      <Loader
        isLoading={
          isLoading || updatingUserAddress || updaingUser || creatingUserAddress
        }
      />
    </div>
  );
};

export default Index;
