// extracted by mini-css-extract-plugin
export var container = "Profile-module--container--jcKmB";
export var heading = "Profile-module--heading--+x+TA";
export var profile__card = "Profile-module--profile__card--+E5uY";
export var profile__card__header = "Profile-module--profile__card__header--E0k1N";
export var profile__card__header__img = "Profile-module--profile__card__header__img--nPWk8";
export var profile__card__header__edit = "Profile-module--profile__card__header__edit--p1L6k";
export var profile__card__content = "Profile-module--profile__card__content--VVtGI";
export var profile__card__content__item = "Profile-module--profile__card__content__item--MOWk0";
export var profile__card__content__item__div = "Profile-module--profile__card__content__item__div--GwEGy";
export var submit = "Profile-module--submit--kSqpi";
export var disable = "Profile-module--disable--8FGNl";
export var input = "Profile-module--input--EPcCO";
export var select = "Profile-module--select--ft3x0";
export var paddingBottom = "Profile-module--paddingBottom--Asn-o";