import React from "react";

import Layout from "../Components/Layout";
import Profile from "../Components/Profile";

const ProfilePage = () => {
  return (
    <Layout isPrivate showHeader>
      <Profile />
    </Layout>
  );
};

export default ProfilePage;
